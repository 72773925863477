<template>
	<div class="grid">
		<div class="col-12 md:col-12">
			<div class="field p-fluid grid">
				<label for="package" class="col-12 md:col-1">{{$t('receipt.package')}}</label>
				<div class="col-12 md:col-3">
					<Dropdown id="package"
						v-model="selectedPackage"
						:options="receipt.packageDtos"
						optionLabel="packageCode"
						@change="onChangePackage($event)"/>
				</div>
			</div>
		</div>
		<div class="col-12 md:col-12">
			<div class="card">
				<DataTable :value="packageItems"
										:showGridlines=true
										editMode="row" dataKey="id" v-model:editingRows="editingRows" @row-edit-save="onRowEditSave" responsiveLayout="scroll">
					<template #header>
						<div class="table-header">
							<div class="block mt-2 md:mt-0">
								<Button icon="pi pi-plus" :label="$t('button.add')"
									@click="onCreateItem"/>
								<i class="mr-2" />
								<Button icon="pi pi-save" :label="$t('button.save')"
									@click="onSaveItems"/>
								<i class="mr-2" />
								<Button :label="$t('button.back')" icon="pi pi-backward" @click="onBack()"/>
								<!--
								<span class="p-input-icon-left" v-if="meta.searchable">
										<i class="pi pi-search" />
										<InputText v-model="filters['global'].value" :placeholder="$t('placeholder.search')" />
								</span>
								-->
							</div>
						</div>
					</template>
						<Column field="Index" header="#" style="width:10%">
								<template #body="{ index }">
										<label>{{index + 1}}</label>
								</template>
						</Column>
						<Column field="name" header="Name" style="width:20%">
								<template #editor="{ data, field }">
										<InputText v-model="data[field]" />
								</template>
						</Column>
						<Column field="quantity" :header="$t('packageItem.quantity')" style="width: 10%">
								<template #editor="{ data, field }">
										<InputNumber v-model="data[field]" />
								</template>
						</Column>
						<Column field="electronic" :header="$t('packageItem.electronic')" style="width:10%">
								<template #editor="{ data, field }">
										<Checkbox v-model="data[field]" binary/>
								</template>
								<template #body="{ data, field }">
										<Checkbox v-model="data[field]" binary diabled/>
								</template>
						</Column>
						<Column field="description" :header="$t('packageItem.description')" style="width:20%">
								<template #editor="{ data, field }">
										<InputText v-model="data[field]" />
								</template>
						</Column>
						<!--
						<Column field="inventoryStatus" header="Status" style="width:20%">
								<template #editor="{ data, field }">
										<Dropdown v-model="data[field]" :options="statuses" optionLabel="label" optionValue="value" placeholder="Select a Status">
												<template #option="slotProps">
														<span :class="'product-badge status-' + slotProps.option.value.toLowerCase()">{{slotProps.option.label}}</span>
												</template>
										</Dropdown>
								</template>

						</Column>
						<Column field="price" header="Price" style="width:20%">
								<template #editor="{ data, field }">
										<InputText v-model="data[field]" />
								</template>
						</Column>
						-->
						<Column :rowEditor="true" style="width:10%; min-width:8rem" bodyStyle="text-align:center"></Column>
				</DataTable>
			</div>
			<!--
			<TabView>
				<TabPanel :header="$t('package.manifests')">
					<CrudTable :entities="packageItems"
						:meta="metaPackageItem"
						@tableAction="onTableAction"
						@itemAction="onItemAction"/>
				</TabPanel>
				<TabPanel :header="$t('package.images')">
					<Galleria :value="images" :responsiveOptions="responsiveOptions" :numVisible="5" :circular="true" containerStyle="max-width: 640px"
						:showItemNavigators="true" :showThumbnails="false">
						<template #item="slotProps">
								<img :src="slotProps.item.itemImageSrc" :alt="slotProps.item.alt" style="width: 100%; display: block;" />
						</template>

						<template #thumbnail="slotProps">
								<img :src="slotProps.item.thumbnailImageSrc" :alt="slotProps.item.alt" style="display: block;" />
						</template>
					</Galleria>
				</TabPanel>
			</TabView>
			-->
		</div>
	</div>

</template>

<script>
	import {FilterMatchMode} from 'primevue/api';
	import ImageService from '@/service/ImageService'
	import PackageItemService from '@/service/PackageItemService'
	import ReceiptService from '@/service/ReceiptService'
	//import PackageService from '@/service/PackageService'
	//import CrudTable from '@/pages/common/CrudTable'
	//import Utils from '@/service/Utils'
	export default {
		components: {},
		data() {
			return {
				receipt:{},
				packageEntity: {},
				selectedPackage: null,
				images:[],
				displayCustom: true,
				responsiveOptions: [
						{
                breakpoint: '1024px',
                numVisible: 5
            },
            {
                breakpoint: '768px',
                numVisible: 3
            },
            {
                breakpoint: '560px',
                numVisible: 1
            }
        ],
				packageItems: [],
				editingItem: null,
				editingRows: [],
				metaPackageItem: {
					entityName: "packageItem",
					selectionMode: "multiple",
					paginator:{
						enable: false
					},
					columns: [
						{
							name: "name",
							headerStyle: "width:20%; min-width:10rem;"
						},
						{
							name: "electronic",
							headerStyle: "width:20%; min-width:10rem;"
						},
						{
							name: "quantity",
							headerStyle: "width:20%; min-width:10rem;"
						}
					],
					tableActions: [
						/*
						{
							name: "create",
							icon: "pi pi-plus",
							style: "p-button-rounded p-button-success mr-2"
						}
						*/
					],
					itemActions: [
						{
							name: "edit",
							icon: "pi pi-pencil",
							style: "p-button-rounded p-button-success mr-2",
							condition: "mode != 'edit'"
						},
						{
							name: "save",
							icon: "pi pi-save",
							style: "p-button-rounded p-button-success mr-2",
							condition: "mode == 'edit'"
						},
						{
							name: "delete",
							icon: "pi pi-trash",
							style: "p-button-rounded p-button-warning mt-2",
							condition: "mode != 'edit'"
						}
					]
				}
			}
		},
		mounted() {
			if(this.$route.params.receiptId) {
				ReceiptService.get(this.$route.params.receiptId).then(data => {
					this.receipt = data;
					if(this.$route.params.packageId) {
						for(var i = 0; i < data.packageEntities.length; i++) {
							if (data.packageEntities[i].id == this.$route.params.packageId) {
								this.selectedPackage = data.packageEntities[i];
								this.loadImages(this.selectedPackage);
								this.loadPackageItems(this.selectedPackage);
								break;
							}
						}
					}
				});
			} else {
				this.receipt = {};
				this.selectedPackage = null;
			}
			this.packageItems=[];
		},
		methods: {
			onBack() {
				this.$router.back()
			},
			onCreateItem() {
				if (this.selectedPackage && (!this.editingRows || this.editingRows.length == 0)) {
					this.editingItem = {
						receiptCode: this.selectedPackage.receiptCode,
						packageCode: this.selectedPackage.packageCode,
						name: null,
						electronic: false,
						quantity: 1
					};
					this.editingRows = [this.editingItem];
					this.packageItems.unshift(this.editingItem);
				}
			},
			onSaveItems() {
				console.log(this.packageItems);
			},
			onRowEditSave(event) {
				let { newData, index } = event;
				PackageItemService.create(newData).then(res => {
					this.packageItems[index] = res;
					this.editingRows = [];
				});
			},
			onPrint() {
				//this.$htmlToPaper('print');
				this.$htmlToPaper({url:'receipt/' + this.receipt.id})
				//this.$htmlToCanvas({url:'receipt/' + this.receipt.id})
			},
			loadImages(receiptPkg) {
				if(receiptPkg) {
					ImageService.loadImages(receiptPkg)
						.then((response) => {
							//const baseUrl = Utils.getImageBase();
							if (Array.isArray(response)) {
								this.images = response.map(content => ({
									itemImageSrc: `data:image/png;base64, ${content}`,
									thumbnailImageSrc: `data:image/png;base64, ${content}`
								}))
							}
						})
				}
			},
			loadPackageItems(receiptPkg) {
				if(receiptPkg) {
					this.packageItems = receiptPkg.packageItems
					this.packageItems.splice(0,0,{mode:'edit'})
				}
			},
			onChangePackage(event) {
				console.log(event);
			},
			onTableAction(sAction) {
				if (sAction == 'create') {
					var pkgItem = {
						mode: 'edit'
					}
					this.packageItems.push(pkgItem);
				}
			},
			onItemAction(sAction, entity, index) {
				if (sAction =='save') {
					if (entity.id) {
						PackageItemService.update(entity).then(response => {
							this.packageItems[index] = response.data
						});
					} else {
						let data = {
							packageCode: this.selectedPackage.packageCode,
							packageId: this.selectedPackage.id,
							receiptCode: this.receipt.receiptCode, ...entity
						};
						PackageItemService.create(data).then(response => {
							this.packageItems[index] = {mode:'edit'};
							this.packageItems.push(response.data);
						});
					}
				} else if (sAction == 'edit') {
					entity.mode = 'edit';
				} else if (sAction == 'delete') {
					PackageItemService.delete(entity.id).then(() => {
						this.packageItems.splice(index, 1);
					});
				}
			},
			onReset() {
				this.receipt = this.originalEntity;
			},
			initManifestFilters() {
          this.filters = {
              'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
          }
      }
		}
	}
</script>
